import React,{useEffect, useContext} from 'react';
import axios from 'axios';
// import logo from './logo.svg';
import './App.scss';

import routes from './routes';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
// import Home from './pages/home';
import { ContentContext } from './context/pageContentContext';
import { SettingContext } from './context/pageSettingContext';

import LogoAnimation from './components/LogoAnimation';
import Navigation from './components/Navigation';

function App() {
  const contentContext  = useContext( ContentContext);
  const settingContext  = useContext( SettingContext);
  
  // useEffect( () => {
    // axios.defaults.baseURL = process.env.REACT_APP_API_URL;
    
  // },[ContentContext]);

  useEffect(() => {
    const fetchData = async() => {
      axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
      axios.defaults.headers.get['Access-Control-Allow-Origin'] = '*';
      await axios.get(process.env.REACT_APP_API_URL+'/pages').then( resp =>{
          // console.log(resp);
          contentContext.setData( resp.data );
          setTimeout(function () {
              settingContext.setLoading( false );
          }, 2000);  
        }).catch( error => {
          console.log(error);
          settingContext.setLoading( false );
        })
    }
    fetchData();
},[]);

  let content = <LogoAnimation />
  if( settingContext.loading === false ){
    content = 
                <React.Suspense fallback ={<LogoAnimation />}>
                  <Router>
                    <Navigation/>
                    <Switch>
                      {routes.map(({path, componentName, exact}, key)=> <Route exact={exact}  key={componentName+key} path={path} component={componentName} />)}
                    </Switch>
                  </Router>
                </React.Suspense>
  }

  return (
    <div className="App">
      {content}
    </div>
  );
}

export default App;
