import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import SettingContextProvider from './context/pageSettingContext';
import PageContextProvider from './context/pageContentContext';
import RendererContextProvider from './context/rendererContext';
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';

ReactDOM.render(
  <React.StrictMode>
    {/* <App /> */}
    <PageContextProvider><SettingContextProvider><RendererContextProvider>
    <GoogleReCaptchaProvider reCaptchaKey="6LcgtrQZAAAAAPL8ICun_ByH3U_2v3JFl2rQWcBK">
        <App />
    </GoogleReCaptchaProvider>
    </RendererContextProvider></SettingContextProvider></PageContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
