import React from 'react';
import SpanTitle from '../SpanTitle';

export default function TextComponent({ data: {url, type, title} = {}, children="", classList=""}){

    let childrenCopy = children;

    if(classList && classList.includes('outline-title')){
        childrenCopy =  <SpanTitle copy={ children } role="heading" />;
    }
    
    let content = <p>{ childrenCopy }</p>;

    if( type === 'h1' ) content = <h1 className={classList} key={type + Math.random()}>{ childrenCopy }</h1>
    if( type === 'h2' ) content = <h2 className={classList} key={type + Math.random()}>{ childrenCopy }</h2>
    if( type === 'h3' ) content = <h3 className={classList} key={type + Math.random()}>{ childrenCopy }</h3>
    if( type === 'h4' ) content = <h4 className={classList} key={type + Math.random()}>{ childrenCopy }</h4>
    if( type === 'h5' ) content = <h5 className={classList} key={type + Math.random()}>{ childrenCopy }</h5>
    if( type === 'qoute' ) content = <em className={classList} key={type + Math.random()}>{ childrenCopy }</em>
    if( type === 'span' ) content = <span className={classList} key={type + Math.random()}>{ childrenCopy }</span>
    if( type === 'icon' ) content = <li><a target="_blank" rel="nofollow noopener"  href={url}><i className={classList} key={type + Math.random()} title={title}>{ childrenCopy }</i></a></li>


    return(
        <>
            {content}
        </>
    );
}