import React from 'react';
import TextComponent from './TextComponent';
import FileComponent from './FileComponent';

export default function WrapperComponent({data}){

    let component;

    if( data.category === 'icon' ){
        component = <TextComponent key={data.type + Math.random()} classList={data.class + ' '+ data.icon} data={data}></TextComponent>
    }
    if(data.category === 'text' ){
        component = <TextComponent key={data.type + Math.random()} classList={ data.class } data={data}>{data.content}</TextComponent>
    }
    if(data.category === 'file'){
        component = <FileComponent key={data.type + Math.random()} classList={ data.class } data={data} />
    }
    if(data.category === 'wrapper'){
        component = data['data'].map(d => {
            return <WrapperComponent key={d.category+ d.types + Math.random()} data={d}></WrapperComponent>
        });
        
        // component = <WrapperComponent data={data}></WrapperComponent>
    }
   

    let wrapper = component;
    if(data.type === 'div'){
        wrapper = <div key={data.type + Math.random()} className={data.class}>{component}</div>;
    }
    if(data.type === 'list'){
        wrapper = <ul key={data.type + Math.random()} className={data.class}>{component}</ul>;
    }
    if(data.type === 'section') wrapper = <section key={data.type + Math.random()} className={data.class}>{component}</section>;

    return(
        <>
            {wrapper}
        </>
    );

}