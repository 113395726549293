import React,{ useState } from 'react';


export const ContentContext = React.createContext({
    data: [],
    current: '',
    setData: () => {}
});

const ContentContextProvider = props => {
    const [ data, setData] = useState([]);

    const [current , setCurrent] = useState(0);
    
    const changeData = ( pageData ) => {
        setData( pageData );
    }

    const getDataBySlug = ( slug ) => {
        let index = data.findIndex( d => d['slug'] === slug.toLowerCase());
        return index;
    }

    const  changeCurrent = (index) =>{
        setCurrent(index)
    }

    return (
        <ContentContext.Provider
        value={{ setData: changeData, data: data, getDataBySlug: getDataBySlug, currentIndex: current, changeCurrent: changeCurrent }}
        >
            {props.children}
        </ContentContext.Provider>
    )
}

export default ContentContextProvider;