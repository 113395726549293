import React from 'react';

export default function SpanTitle(props){
  
    return(
        <span aria-label={props.copy} role={props.role}>
            {props.copy.split("").map((char, index) => {
                return <span aria-hidden="true" key={index}>{char}</span>;
            })}
        </span>
    );
}