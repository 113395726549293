import React,{ useReducer } from 'react';

const options =  ["3A", "2D", "3P"];

export const RendererContext = React.createContext({
    mode: "",
    setMode: () => {}
});

function init(initialMode) {
    return { initialMode};
}

function reducer( state, action){
    switch (action.type) {
        case options[0]:
          return  options[0];
        case options[1]:
          return  options[1];
        case options[2]:
          return  options[2];
        case 'reset':
            return init(action.payload);
        default:
          throw new Error();
      }
}

const RendererContextProvider = props => {
    const [ mode, dispatch] = useReducer(reducer, options[0]);

    return (
        <RendererContext.Provider
        value={{ setMode: dispatch, mode: mode, options: options }}
        >
            {props.children}
        </RendererContext.Provider>
    )
}

export default RendererContextProvider;