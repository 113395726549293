import React,{ useState } from 'react';


export const SettingContext = React.createContext({
    slug: "",
    loading: "",
    setSlug: () => {},
    setLoading: () => {}
});

const SettingContextProvider = props => {
    const [ slug, setSlug] = useState("Rachet.tech");
    const [ loading, setLoading] = useState(true);
    
    const changeSlug = ( title ) => {
        setSlug( title );
    }

    const changeLoading = ( status ) => {
        setLoading( status );
    }

    return (
        <SettingContext.Provider
        value={{ setSlug: changeSlug, slug: slug, loading: loading, setLoading: changeLoading }}
        >
            {props.children}
        </SettingContext.Provider>
    )
}

export default SettingContextProvider;