import React, { useState, useContext, useEffect, useRef, useMemo } from 'react';
import anime from 'animejs';

import { useLocation } from 'react-router-dom'
import routes from '../routes';
import {Link} from 'react-router-dom';

import { SettingContext } from '../context/pageSettingContext';
import { ContentContext } from '../context/pageContentContext';
import { RendererContext } from '../context/rendererContext';

import WrapperComponent from '../components/builder/WrapperComponent';

const Navigation = () => {

    const settingContext  = useContext( SettingContext);
    const contentContext  = useContext( ContentContext);
    const rendererContext  = useContext( RendererContext);
    const [navState, setNavState] = useState({state: 'inactive'});
    const location = useLocation();
  
    const svgPath = [ 
        "M1919.5,0.5c0.32,0,0.32,235,0,235v174c-1.31,0-0.31,448,1,448c0.63,0-1.63,223-1,223V0.5z",
        "M1919.5,0.5c-8.06-4.86-13.07,228.7,0,235c2.42,1.16,2.6,173.34,0,174c-18.02,4.61-4.79,449.42,1,448c113.54-27.83-511.92,230.41-1,223V0.5z",
        "M1919.5,0.5c-844.75-16.7-929.39,57.03-897,201c33.21,147.63,388,188,288,338c-70.29,105.44-141,129-125,272c13,116.18,223.08,276.41,734,269V0.5z",
        "M1919.5,0.5c-844.75-16.7-1348.39-9.97-1316,134c33.21,147.63-8,193-108,343c-70.29,105.44,119,313,135,456c13,116.18,778.08,154.41,1289,147V0.5z",
        "M1919.5,0.5c0,0.39-1925-0.61-1925-1c0-4.29,9.29,489.96,5,490c-0.67,0.01,0.67,591,0,591c-0.67,0,1920-0.67,1920,0V0.5z",
     ]
    const menuContainer = useRef();
    const toggleNavState = () => {
        const navCurrentState = (navState.state === 'inactive')?'active':'inactive';
        (navCurrentState === "active")? animateIn(navCurrentState) :  animateOut(navCurrentState);
    };
    const animateIn = (navCurrentState) =>{
        setNavState({state: navCurrentState});
        const timeline = anime.timeline( {easing: 'linear'} );
        timeline
        // .add({ targets: menuContainer.current, translateX: ['100vw', '0'], duration: 500 })
        .add({ targets: '.menu-bg-svg path', d: [
            {value: svgPath[0]},
            {value: svgPath[1]},
            {value: svgPath[2]},
            {value: svgPath[3]},
            {value: svgPath[4]},
            ],
            easing: 'linear' , 
            duration: 1000 })
        .add({ targets: ".menu-bg-logo",  opacity: 1, duration: 200})
        .add({ targets: `.menu-container .left-container > *`, opacity: 1, duration: 200, delay: anime.stagger(100) })
        .add({ targets: `.menu-container .right-container ul li`, opacity: 1, duration: 200, delay: anime.stagger(100) })
    }
    const animateOut = (navCurrentState) =>{
        const timeline = anime.timeline( {easing: 'linear'} );
        timeline
        .add({ targets: `.menu-container .left-container > *`, opacity: 0, duration: 100, delay: anime.stagger(100, {direction: 'reverse'}) })
        .add({ targets: `.menu-container .right-container ul li`, opacity: 0, duration: 100, delay: anime.stagger(100, {direction: 'reverse'}) })
        .add({ targets: ".menu-bg-logo", opacity: 0, duration: 100 })
        .add({ targets: '.menu-bg-svg path', d: [
            {value: svgPath[4]},
            {value: svgPath[3]},
            {value: svgPath[2]},
            {value: svgPath[1]},
            {value: svgPath[0]},
            ],
            easing: 'linear' , 
            duration: 700 })
        .add({ targets: ".menu-bg-logo", 
            // translateX: [0, '100vw'], 
            duration: 1,
            complete: ()=>{ setNavState({state: navCurrentState}); }});

    }
 
    useEffect(() => {
        let index = routes.findIndex( route => route.path === location.pathname);
        if(index >= 0){
            if(routes[index].name.toLowerCase() === 'case'){
                return settingContext.setSlug( 'Case' );
            }
            const task = async() => {await contentContext.changeCurrent(contentContext.getDataBySlug( routes[index].name ));}
            task();
           
            settingContext.setSlug( contentContext.data[contentContext.currentIndex]['title'] );
        }
    },[location,settingContext, contentContext]);

    const [dark, setDark] = useState(false);
    useEffect(() => {(dark)? document.body.classList.add('dark') : document.body.classList.remove('dark')} );
    const toggleRenderer = () => {
            setDark(!dark);
    }
    
 
    const dynamic = useMemo( () => {
        let i = contentContext.getDataBySlug('navigation');
        let dy = contentContext.data[i]['content'].map((content, index) => {
            let theContent = content['data'].map((wrapper) => {
                return <WrapperComponent key={wrapper.category+ wrapper.types + Math.random()} data={wrapper}></WrapperComponent>
            });
            return theContent;
        });
        return dy;
    },[contentContext]);

    return(
        <header id="main-head" className="main-head">
        <div className="page-slug" id="page-slug">
            { settingContext.slug }
        </div>
        <nav id="main-navigation">
            <div className="btn-container grid-container grid-col-2">
                <div>
                    <label className="screen-reader-text" htmlFor="theme">Toggle Theme</label>
                    <div className="toggle-button r" id="theme-btn">
                        <input name="theme" id="theme-toggle" onClick={toggleRenderer} type="checkbox" className="checkbox"/>
                        <div className="knobs"></div>
                        <div className="layer"></div>
                    </div>
                </div>              
                <div>
                    <label className="screen-reader-text" htmlFor="render-btn">Toggle 2D/3D</label>
                    <div className="three-states-button r" id="render-btn">
                    {rendererContext.options.map((option, index) => <input type="radio" className={`radiobox option${index+1}`} key={index+"renderoption"} checked={option === rendererContext.mode} onChange={() => rendererContext.setMode({type: option})} name="three-options" value={option}  />)}
                        <div className="knobs"></div>
                        <div className="layer"></div>
                    </div>
                </div>
                
            </div>
            <svg id="ham-menu" className={`ham hamRotate ham1 ${navState.state}`} viewBox="0 0 100 100" width="70" 
            onClick={toggleNavState}>
            <path
                    className="line top"
                    d="m 30,35 h 40 c 0,0 9.044436,-0.654587 9.044436,-8.508902 0,-7.854315 -8.024349,-11.958003 -14.89975,-10.85914 -6.875401,1.098863 -13.637059,4.171617 -13.637059,16.368042 v 40" />
            <path
                    className="line middle"
                    d="m 30,48 h 40" />
            <path
                    className="line bottom"
                    d="m 30,61 h 40 c 12.796276,0 15.357889,-11.717785 15.357889,-26.851538 0,-15.133752 -4.786586,-27.274118 -16.667516,-27.274118 -11.88093,0 -18.499247,6.994427 -18.435284,17.125656 l 0.252538,40" />
            </svg>
        </nav>
        <div ref={menuContainer} className={`menu-container ${navState.state}`} id="menu-container">
            <div className="menu-bg-logo"></div>
            <svg className="menu-bg-svg" xmlns="http://www.w3.org/2000/svg" >
            <path d={svgPath[0]}/></svg>
            <div className="left-container">
                {dynamic}
                {/* <h3>Reach me by:</h3>
                <span>rachet.khanal@gmail.com</span> */}
                {/* <ul className="social-list">
                    {socials.map( (social, index) => <li key={`nav-${index}-${social.title}`}><a rel="nofollow" href={social.url}><i  className={social.icon}></i></a></li> )}
                </ul> */}
                <ul className="footer-menu">
                    <li><a href="#">Privacy Policies</a></li>
                    <li><a href="#">Terms & Conditions</a></li>
                </ul>
                <span>Copyright @2020.</span>
            </div>
            <div className="right-container">
                <ul className="header-menu">
                    {routes.map(({path, name}, key)=>  <li key={name+key}><Link onClick={toggleNavState} to={path}>{name}</Link></li> )}
                </ul>
               
            </div>
                
            </div>
    </header>
    );
}

export default Navigation;