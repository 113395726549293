import React from 'react';

export default function FileComponent({ data: {src, type, title, alt} = {}, children="", classList=""}){
    
    let content = <img src={src} alt={alt} />;

    if( type === 'pdf' ) content = <embed className={classList} title={title} key={type + Math.random()} src={src}
                                    width="500" height="375" type="application/pdf"></embed>
    return(
        <>
            {content}
        </>
    );
}