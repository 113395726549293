import React from "react";

const Home = React.lazy(() => import("./pages/home"));
const Case = React.lazy(() => import("./pages/case"));
const Contact = React.lazy(() => import("./pages/contact"));

export default [
    { name: 'Home', exact:true, path: '/', componentName: Home },
    { name: 'About', exact:true, path: '/#about', componentName: Home },
    { name: 'Case', exact:false, path: '/case', componentName: Case },
    { name: 'Contact', exact:false, path: '/contact', componentName: Contact} ,
];


